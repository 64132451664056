<template>
  <g>
    <svg:style>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 11.16"
      :y1="doorTopHeight1 + 148.47"
      :x2="doorLeftWidth1 + 16.74"
      :y2="doorTopHeight1 + 148.47"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 59.59"
      :y1="doorTopHeight1 + 275.05"
      :x2="doorLeftWidth1 + 59.59"
      :y2="doorTopHeight1 + 21.9"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 80.13"
      :y1="doorTopHeight1 + 275.05"
      :x2="doorLeftWidth1 + 80.13"
      :y2="doorTopHeight1 + 21.9"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 + 238.12"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 + 59.6"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f5"/>
      <stop offset="0.5" stop-color="#dde7e6"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <g id="V25">
      <rect
        id="inox"
        v-if="showInox"
        class="cls-4"
        :x="doorLeftWidth1 + 58.41"
        :y="inoxY"
        width="2.37"
        :height="inoxH"/>
      <rect
        id="inox-2"
        v-if="showInox"
        data-name="inox"
        class="cls-5"
        :x="doorLeftWidth1 + 78.95"
        :y="inoxY"
        width="2.37"
        :height="inoxH"/>
      <polyline
        id="glass"
        v-if="showInox"
        class="cls-6"
        :points="`
        ${doorLeftWidth1 + 75.48} ${doorTopHeight1 + 238.12} ${doorLeftWidth1 + 75.48} ${doorTopHeight1 + 59.6} ${doorLeftWidth1 + 64.34} ${doorTopHeight1 + 59.6} ${doorLeftWidth1 + 64.34} ${doorTopHeight1 + 238.12} ${doorLeftWidth1 + 75.48} ${doorTopHeight1 + 238.12}
        `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset * 2 :
        this.doorHeight - this.leafOffset * 2
    },
  }
}
</script>
